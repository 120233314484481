import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"
const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/manufacturing/",
    label: "MANUFACTURING",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="MANUFACTURING" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>MANUFACTURING</h1>
              <h4>PROTECTING YOUR PRODUCTS AND PEOPLE</h4>
              <p className="content">
                Making Certain Your Production Continues Unencumbered
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-manu">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                PROTECT <br /> OPERATIONAL <br /> TECHNOLOGY
              </h3>
              <h6>
                PRODUCT CONTINUITY <br />
                SYSTEMS SECURITY.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              When your goal is protecting a large facility, you install
              smart-locks, window sensors and an abundance of cameras – so you
              can see every dark corner and blind spot.
            </p>
            <p>
              Vigilant takes the same approach. We simply have more
              “smart-locks,” more “sensors” and more “cameras.” Quite a few
              more. Why does that matter? Because, in cybersecurity, you have to
              see everything, even in the gaps.
            </p>
            <p>
              Simply put, breaches stop production. Out-of-date technologies,
              ever-evolving tech and integrating IoT and OT all require air gap
              networks and a watchful eye. We detect threats early so they never
              make it to the production line.
            </p>

            <p>
              From our 24/7/365 US-based CyberDNA® Command and Control Center,
              we watch your network, endpoints, email flow, operational
              technology and your IoT (providing air gap tech). We secure your
              production floor, gateways and third-party connections (all the
              way into your supply chain). We inspect all of your cyberdata, and
              we constantly monitor for malicious activity.
            </p>
            <p>
              When your staff needs to focus on production, we keep you cyber
              secure.
            </p>
            <p>
              From protecting your systems to operating technology to critical
              infrastructure, we keep you up and running safely.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “I have been a VigilantMEDR customer for over a year. I have
                  been extremely happy with the services they provide because I
                  can sleep at night knowing I’m not going to get breached. I
                  know Vigilant is truly the best in the business and are always
                  looking out for my business’ best interest.”
                </h3>
                <hr />
                <h6>- CLIENT IN MANUACTURING INDUSTRY</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
